import logo from './logo.svg';
import instagramLogo from './Instagram_Glyph_Gradient.svg';
import './App.css';

function App() {
  return (
    <section className="main-section">
      <div className="app-header">
        <img src={logo} className="logo-image" alt="Un Autre Logo" />
      </div>

      <div className="app-body">
        <div className="un-autre-description">
          <p>
            <span>Un Autre</span> offers you freshly baked homemade brownies, made with love and care.
          </p>
          <p>
            Our products are made with the best ingredients, and we are always looking for new ways to innovate and create new recipes.
          </p>
        </div>

        <a href="https://instagram.com/unautre.mtl" className="instagram-box" target="_blank" rel="noopener noreferrer" alt="Un Autre Instagram Account" >
          <p>
            Follow us on <span>Instagram</span>
          </p>
          <img src={instagramLogo} alt="Instagram Logo" />
        </a>
      </div>

      <div className="app-footer"></div>
    </section>
  );
}

export default App;
